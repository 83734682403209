import { render, staticRenderFns } from "./dictsList.vue?vue&type=template&id=3540b51c&scoped=true&"
import script from "./dictsList.vue?vue&type=script&lang=js&"
export * from "./dictsList.vue?vue&type=script&lang=js&"
import style0 from "./dictsList.vue?vue&type=style&index=0&id=3540b51c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3540b51c",
  null
  
)

export default component.exports